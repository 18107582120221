<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-row>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Pilih No SPPD</h6>
            <p class="card-text mb-25">{{ detail.sppd_number }}</p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6>{{ employee.type === 'pegawai' ? 'NIPPOS' : 'NIK' }} / Nama</h6>
            <p class="card-text mb-25">
              {{ employee.type === 'pegawai' ? employee.nippos : employee.nik }}
              /
              {{ employee.type === 'pegawai' ? employee.nama : employee.name }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Jenis Fasilitas</h6>
            <p class="card-text mb-25">
              {{ sppdFacilityType }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Jabatan</h6>
            <p class="card-text mb-25">
              {{
                employee.type === 'pegawai'
                  ? employee.descjabatan
                  : employee.position
              }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Bagian/Unit Kerja</h6>
            <p class="card-text mb-25">
              {{ detail.division || '-' }}
            </p>
          </b-col>
        </b-row>
        <hr />
        <div class="mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mr-1"
            @click="openAdd"
          >
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Tambah</span>
          </b-button>
          <b-button
            v-if="status == 0 && can('approval_admin/pengeluaran-rill')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            @click="confirmApproveAll()"
          >
            <feather-icon icon="CheckIcon" class="mr-25" />
            <span>Setujui Semua</span>
          </b-button>
        </div>
        <div>
          <!-- Row Loop -->
          <b-table
            striped
            hover
            responsive
            show-empty
            :items="items"
            :fields="fields"
            ref="tableFe"
          >
            <template #cell(no)="data">{{ data.index + 1 }}</template>

            <template #cell(total_cost)="data">
              {{ data.item.total_cost | currency }}
            </template>
            <template #cell(receipt)="data">
              <span v-if="data.item.receipt != ''">
                <b-link
                  class="btn btn-info"
                  target="_blank"
                  :href="data.item.file"
                >
                  Cek Kwitansi
                </b-link>
              </span>
              <span v-else> Belum ada kwitansi </span>
            </template>

            <!-- A virtual column -->
            <template #cell(aksi)="data">
              <div style="min-width: 120px">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  size="sm"
                  class="btn-icon mr-1"
                  @click="gotoEditPage(data.item.id)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-if="
                    data.item.status == null &&
                    can('approval_admin/pengeluaran-rill')
                  "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  size="sm"
                  class="btn-icon mr-1"
                  @click="confirmApprove(data.item.id)"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>
                <b-button
                  v-if="
                    data.item.status == null &&
                    can('approval_admin/pengeluaran-rill')
                  "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="btn-icon mr-1"
                  @click="confirmReject(data.item.id)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  size="sm"
                  class="btn-icon"
                  @click="confirmDelete(data.item.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </template>

            <template #cell(status)="data">
              <div style="max-width: 80px">
                <template v-if="data.item.status == 1">Disetujui</template>
                <template v-else-if="data.item.status == 0">Ditolak</template>
                <template v-else>Menunggu Approval</template>
              </div>
            </template>
          </b-table>
          <!-- Modal -->
          <b-modal
            v-model="showEdit"
            id="modal-edit"
            ref="my-modals"
            title="Ubah Data"
            size="md"
            cancel-variant="outline-secondary"
          >
            <b-alert
              v-height-fade
              dismissible
              v-model="showDismissibleAlert"
              fade
              class="mb-2"
              variant="danger"
              v-id="showDismissibleAlert == true"
            >
              <div class="alert-body">
                <span>{{ messages }}</span>
              </div>
            </b-alert>
            <div class="hidden-scrollbar">
              <div class="inner">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(save)">
                    <b-row class="mr-0">
                      <b-col md="12">
                        <ValidationProvider
                          name="Komponen Biaya"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label label-for="name"
                            >Komponen Biaya
                            <span class="fs-6 text-danger">*</span></label
                          >
                          <v-select
                            v-model="data.cost_component_id"
                            :options="cost"
                            :reduce="item => item.id"
                            label="name"
                          />
                          <p class="fs-6 text-danger">
                            {{ errors[0] }}
                          </p>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="12">
                        <ValidationProvider
                          name="Biaya"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label label-for="name"
                            >Biaya
                            <span class="fs-6 text-danger">*</span></label
                          >
                          <vue-numeric
                            v-model="data.total_cost"
                            name="total_cost"
                            @keypress.native="NumbersOnly"
                            class="form-control"
                            placeholder="Biaya"
                            currency="Rp"
                            separator="."
                          ></vue-numeric>
                          <p class="fs-6 text-danger">
                            {{ errors[0] }}
                          </p>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="12">
                        <ValidationProvider
                          name="Catatan"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label label-for="note"
                            >Catatan
                            <span class="fs-6 text-danger">*</span></label
                          >
                          <b-form-textarea
                            id="mc-name"
                            rows="3"
                            placeholder="Masukkan Catatan"
                            v-model="data.note"
                          />
                          <p class="fs-6 text-danger">
                            {{ errors[0] }}
                          </p>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="12">
                        <ValidationProvider
                          name="Kwitansi"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group>
                            <label
                              >Upload Kwitansi
                              <span class="fs-6 text-danger">*</span></label
                            >
                            <input
                              type="file"
                              accept=".png, .jpg, .jpeg, pdf, doc, docx"
                              @change="storeUpload($event)"
                              name="receipt"
                              class="form-control"
                            />
                            <input
                              type="hidden"
                              id="mc-name"
                              v-model="data.receipt"
                            />
                            <a
                              v-if="currentReceiptUrl"
                              :href="currentReceiptUrl"
                              target="_blank"
                              class="d-block text-info mt-1"
                            >
                              {{ currentReceiptUrl }}
                            </a>
                            <p class="fs-6 text-danger">{{ errors[0] }}</p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <footer
                      id="modal-edit___BV_modal_footer_"
                      class="modal-edit-footer"
                    >
                      <div class="w-100 mt-2">
                        <b-button
                          variant="primary"
                          size="md"
                          class="float-right btn btn-primary mr-1"
                          type="submit"
                          :disabled="isUploadLoading"
                        >
                          Ubah
                        </b-button>
                        <b-button
                          variant="outline-secondary"
                          size="md"
                          class="float-right btn btn-outline-secondary mr-1"
                          @click="closeEdit()"
                        >
                          Batal
                        </b-button>
                      </div>
                    </footer>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </b-modal>
          <b-modal
            v-model="showAdd"
            id="modal-edit"
            ref="my-modals"
            title="Tambah Data"
            size="md"
            cancel-variant="outline-secondary"
          >
            <b-alert
              v-height-fade
              dismissible
              v-model="showDismissibleAlert"
              fade
              class="mb-2"
              variant="danger"
              v-id="showDismissibleAlert == true"
            >
              <div class="alert-body">
                <span>{{ messages }}</span>
              </div>
            </b-alert>
            <div class="hidden-scrollbar">
              <div class="inner">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(add)">
                    <b-row class="mr-0">
                      <b-col md="12">
                        <ValidationProvider
                          name="Komponen Biaya"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label label-for="name"
                            >Komponen Biaya
                            <span class="fs-6 text-danger">*</span></label
                          >
                          <v-select
                            v-model="data.cost_component_id"
                            :options="cost"
                            :reduce="item => item.id"
                            label="name"
                          />
                          <p class="fs-6 text-danger">
                            {{ errors[0] }}
                          </p>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="12">
                        <ValidationProvider
                          name="Biaya"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label label-for="name"
                            >Biaya
                            <span class="fs-6 text-danger">*</span></label
                          >
                          <vue-numeric
                            v-model="data.total_cost"
                            name="total_cost"
                            @keypress.native="NumbersOnly"
                            class="form-control"
                            placeholder="Biaya"
                            currency="Rp"
                            separator="."
                          ></vue-numeric>
                          <p class="fs-6 text-danger">
                            {{ errors[0] }}
                          </p>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="12">
                        <ValidationProvider
                          name="Catatan"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label label-for="note"
                            >Catatan
                            <span class="fs-6 text-danger">*</span></label
                          >
                          <b-form-textarea
                            id="mc-name"
                            rows="3"
                            placeholder="Masukkan Catatan"
                            v-model="data.note"
                          />
                          <p class="fs-6 text-danger">
                            {{ errors[0] }}
                          </p>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="12">
                        <ValidationProvider
                          name="Kwitansi"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group>
                            <label
                              >Upload Kwitansi
                              <span class="fs-6 text-danger">*</span></label
                            >
                            <input
                              type="file"
                              accept=".png, .jpg, .jpeg, pdf, doc, docx"
                              @change="storeUpload($event)"
                              name="receipt"
                              class="form-control"
                            />
                            <input
                              type="hidden"
                              id="mc-name"
                              v-model="data.receipt"
                            />
                            <p class="fs-6 text-danger">{{ errors[0] }}</p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <footer
                      id="modal-edit___BV_modal_footer_"
                      class="modal-edit-footer"
                    >
                      <div class="w-100 mt-2">
                        <b-button
                          variant="primary"
                          size="md"
                          class="float-right btn btn-primary mr-1"
                          type="submit"
                          :disabled="isUploadLoading"
                        >
                          Tambah
                        </b-button>
                        <b-button
                          variant="outline-secondary"
                          size="md"
                          class="float-right btn btn-outline-secondary mr-1"
                          @click="closeAdd()"
                        >
                          Batal
                        </b-button>
                      </div>
                    </footer>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </b-modal>
        </div>
        <b-card-footer>
          <!-- back -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="outline-secondary"
            :to="{ name: 'pengeluaran-rill' }"
          >
            Kembali
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BTable,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      name: 'UsersForm',
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: '/real-expenses',
        sppd: '/misc/sppds',
        detailsppd: '/sppd/detail_info/',
        cost: '/misc/cost-components',
        redirect: '/pengeluaran-rill/' + this.$route.params.id + '/edit',
      },
      data: {
        cost_component_id: '',
        note: '',
        total_cost: '',
        receipt: '',
        status: 0,
      },
      real_expenses_id: '',
      showEdit: false,
      showAdd: false,
      isUploadLoading: false,
      sppd: [],
      detail: [],
      cost: [],
      fields: [
        'no',
        {
          key: 'cost_component.name',
          label: 'Komponen Biaya',
          thStyle: 'width:150px',
        },
        { key: 'total_cost', label: 'Biaya' },
        { key: 'note', label: 'Catatan', thStyle: 'width:150px' },
        { key: 'receipt', label: 'Kwitansi' },
        { key: 'aksi', label: 'Aksi', thStyle: 'width:250px' },
        { key: 'status', label: 'Status' },
      ],
      items: [],
      currentReceiptUrl: null,
      messages: '',
      status: '',
      folderName: '',
    }
  },
  computed: {
    sendDisabledState() {
      if (!this.selectedTravelAgent) {
        return this.real_expenses.length === 0 || !this.kwitansi
      }
      return false
    },
    sppdFacilityType() {
      if (this.detail.sppd_facility_type_id == 1) {
        return 'Perjalanan Dinas Biasa'
      }
      if (this.detail.sppd_facility_type_id == 2) {
        return 'Perjalanan Dinas Fasilitas Khusus'
      }
      return '-'
    },
    employee() {
      return this.detail.employee?.raw
        ? JSON.parse(this.detail.employee.raw)
        : {}
    },
  },
  mounted() {
    let _ = this
    _.getData()
    _.getSppd()
    _.getCost()
  },
  methods: {
    NumbersOnly(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    storeUpload(event) {
      this.data.receipt = event.target.files[0]
      this.isUploadLoading = true
      let formData = new FormData()
      formData.append('file', this.data.receipt)
      formData.append('folder', this.folderName)
      formData.append('type', 'pengeluaran rill')
      axios
        .post('/misc/upload-file', formData)
        .then(res => {
          this.isUploadLoading = false
          this.data.receipt = res.data.filename
        })
        .catch(err => {
          this.isUploadLoading = false
          this.data.receipt = null
          this.errors = 'Kwitansi gagal di upload'
        })
    },
    gotoEditPage(id) {
      let _ = this
      _.errors = ''
      _.messages = ''
      _.showEdit = true
      _.real_expenses_id = id
      axios.get(_.config.api + '/' + id + '/detail').then(res => {
        const { data } = res.data
        const { total_cost, cost_component_id, note, receipt, file } = data

        this.currentReceiptUrl = file

        this.data = {
          cost_component_id,
          total_cost,
          note,
          receipt: receipt.split('/').at(-1),
        }
      })
    },
    closeEdit() {
      let _ = this
      _.showEdit = false
      _.data.cost_component_id = ''
      _.data.note = ''
      _.data.total_cost = ''
      _.data.receipt = ''
      _.errors = ''
      _.messages = ''
    },
    openAdd() {
      let _ = this
      _.showAdd = true
      _.data.cost_component_id = ''
      _.data.note = ''
      _.data.total_cost = ''
      _.data.receipt = ''
      _.errors = ''
      _.messages = ''
    },
    closeAdd() {
      let _ = this
      _.showAdd = false
      _.data.cost_component_id = ''
      _.data.note = ''
      _.data.total_cost = ''
      _.data.receipt = ''
      _.errors = ''
      _.messages = ''
    },
    confirmApproveAll() {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menyetujui semua data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .put(`${this.config.api}/${this.$route.params.id}/approve/all`)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Berhasil semua disetujui',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getData()
              this.$refs.tableFe.refresh()
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Tidak bisa semua disetujui',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    confirmApprove(id) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menyetujui data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .put(`${this.config.api}/${id}/approve`)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Berhasil disetujui',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getData()
              this.$refs.tableFe.refresh()
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Tidak bisa disetujui',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    confirmReject(id) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menolak data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .put(`${this.config.api}/${id}/reject`)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Berhasil ditolak',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getData()
              this.$refs.tableFe.refresh()
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Tidak bisa ditolak',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(`${this.config.api}/${id}`)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Berhasil dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.items = this.items.filter(item => item.id !== id)
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Tidak bisa dihapus',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    getSppd() {
      let _ = this
      axios.get(_.config.sppd).then(response => {
        let _ = this
        _.sppd = response.data.data
      })
    },
    getCost() {
      let _ = this
      axios.get(_.config.cost).then(response => {
        let _ = this
        _.cost = response.data.data
      })
    },
    getDetailSppd(id) {
      let _ = this
      _.data.cost_component_id = ''
      _.data.note = ''
      _.data.total_cost = ''
      _.data.receipt = ''
      axios.get(_.config.detailsppd + id).then(response => {
        let _ = this
        _.detail = response.data.data
      })
    },
    getData() {
      let _ = this
      console.log(_.config.redirect)
      axios.get(_.config.api + '/' + this.$route.params.id).then(response => {
        let _ = this
        _.model = response.data.data
        _.model.sppd_id = _.model.sppd_number
        _.folderName = _.model.folder
        _.items = _.model.real_expenses
        _.status = _.items.findIndex(x => x.status === null)
        axios
          .get(_.config.detailsppd + _.model.employee.sppd_id)
          .then(response => {
            let _ = this
            _.detail = response.data.data
          })
      })
    },
    add() {
      let _ = this
      axios
        .post('/sppd/' + _.$route.params.id + '/real-expense', _.data)
        .then(res => {
          _.$swal({
            icon: 'success',
            title: 'Tambah Data Berhasil',
            text: 'Data berhasil ditambah',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          _.showAdd = false
          _.getData()
          _.$refs.tableFe.refresh()
        })
        .catch(e => {
          let vm = this
          vm.showDismissibleAlert = true
          if (typeof e.response.data.message === 'object') {
            vm.messages = e.response.data.message.errors[0].msg
            vm.errors = e.response.data.data
          } else {
            vm.messages = e.response.data.message
            vm.errors = e.response.data.data
          }
        })
    },
    save() {
      let _ = this
      axios
        .put(_.config.api + '/' + _.real_expenses_id, _.data)
        .then(res => {
          _.$swal({
            icon: 'success',
            title: 'Ubah Data Berhasil',
            text: 'Data berhasil diubah',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          _.showEdit = false
          _.getData()
          _.$refs.tableFe.refresh()
        })
        .catch(e => {
          let vm = this
          vm.showDismissibleAlert = true
          if (typeof e.response.data.message === 'object') {
            vm.messages = e.response.data.message.errors[0].msg
            vm.errors = e.response.data.data
          } else {
            vm.messages = e.response.data.message
            vm.errors = e.response.data.data
          }
        })
    },
  },
}
</script>
